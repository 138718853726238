import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

Vue.use(Vuex)

const apiUrl = process.env.VUE_APP_API_URL
const tokenType = 'Bearer'

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
    userId: '',
    profile: {},
    preloader: false,
    preload: false,
    language: localStorage.getItem('lang'),
    correct: 0,
    grade: 0
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    preloaderState: state => state.preloader,
    preloadS: state => state.preload,
    getProfile: state => state.profile,
    getLanguage: state => state.language,
    getGrade: state => state.grade,
    getCorrect: state => state.correct
  },
  mutations: {
    auth_request (state) {
      state.status = 'loading'
    },
    auth_success (state, token, user, role) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error (state) {
      state.status = 'error'
    },
    logout (state) {
      state.status = ''
      state.token = ''
    },
    profile (state, user) {
      state.profile = user
    },
    preloaderStatus (state) {
      state.preloader = !state.preloader
    },
    setGrade (state, item) {
      state.grade += item
    },
    setCorrect (state, item) {
      state.correct += item
    },
    resetGrade (state, item) {
      state.correct = item
      state.grade = item
    },
    setPreload (state, item) {
      state.preload = item
    }
  },
  actions: {
    // functions to control total grade in exercise_list with timer
    setGrade: ({ commit }, grade) => {
      commit('setGrade', grade)
    },
    setCorrect: ({ commit }, grade) => {
      commit('setCorrect', grade)
    },
    resetGrade: ({ commit }, grade) => {
      commit('resetGrade', grade)
    },
    // function to preload page
    activePreload: ({ commit }, item) => {
      commit('setPreload', item)
    },
    // function to login
    login ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/accounts/login/',
          data: user,
          method: 'POST'
        })
          .then(resp => {
            if (resp.data.access !== undefined) {
              const decoded = jwtDecode(resp.data.access)
              commit('profile', decoded)
              const localStorageArray = {
                user: decoded.first_name,
                rle: decoded.role,
                tf_st: decoded.tariff_status,
                fst_vist: decoded.first_visit,
                tm_in: new Date().toISOString().slice(0, 10),
                mail: decoded.email,
                id: decoded.user_id,
                sd: decoded.school_id
              }
              localStorage.setItem('currency', decoded.currency)
              localStorage.setItem('uData', JSON.stringify(localStorageArray))
              localStorage.setItem('lang', 0)
              localStorage.setItem('nb', 6)
              const token = resp.data.access
              localStorage.setItem('token', tokenType + ' ' + token)
              axios.defaults.headers.common.Authorization = tokenType + ' ' + token
              commit('auth_success', token, user)
            }
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            commit('auth_error')
            // localStorage.clear()
            localStorage.removeItem('currency')
            localStorage.removeItem('uData')
            localStorage.removeItem('nb')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    // function to get guest course list
    getGuestCourseList ({ commit }, filter) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/guest_course_in_catalog/?q=${filter.q}&university=${filter.univer}&speciality=${filter.faculty}&cost_min=${filter.cost_min}&city=${filter.city}&cost_max=${filter.cost_max}&price_min=${filter.price_min}&price_max=${filter.price_max}&tag=${filter.tag}&degree=${filter.degree}&lang=${filter.lang}&translate_lang=${filter.translate_lang}&page=${filter.page}&domain=${filter.domain}&start=${filter.start_date}&end=${filter.end_date}&is_open=${filter.is_open}&format_fil=${filter.format}&univer_tag=${filter.univer_tag}&cost_value=${filter.cSort}&price_value=${filter.pSort}`,
          signal: filter.controller.signal,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get course list at catalog page
    getCourseListCatalog ({ commit }, filter) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/guest_course_in_catalog/?q=${filter.q}&univer_tag=${filter.univer_tag}&university=${filter.univer}&speciality=${filter.faculty}&cost_min=${filter.cost_min}&city=${filter.city}&cost_max=${filter.cost_max}&price_min=${filter.price_min}&price_max=${filter.price_max}&tag=${filter.tag}&degree=${filter.degree}&lang=${filter.lang}&translate_lang=${filter.translate_lang}&page=${filter.page}&domain=${filter.domain}&start=${filter.start_date}&end=${filter.end_date}&is_open=${filter.is_open}&format_fil=${filter.format}`,
          signal: filter.controller.signal,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get a data bout course in guest page
    getCourseByIDGuestPage ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/guest_course/${data.id}/?translate_lang=${data.translate_lang}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get course list
    getCourseList ({ commit }, filter) {
      return new Promise((resolve, reject) => {
        const date = JSON.parse(localStorage.getItem('uData'))
        const schoolId = date.sd
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/catalog/${schoolId}/?q=${filter.q}&univer_tag=${filter.univer_tag}&university=${filter.univer}&speciality=${filter.faculty}&cost_min=${filter.cost_min}&city=${filter.city}&cost_max=${filter.cost_max}&tag=${filter.tag}&page=${filter.page}&lang=${filter.lang}&degree=${filter.degree}&price_min=${filter.price_min}&price_max=${filter.price_max}&format_fil=${filter.format}&translate_lang=${filter.translate_lang}&start=${filter.start}&end=${filter.end}&is_open=${filter.is_open}&changed_at_after=${filter.changed_at_after}&changed_at_before=${filter.changed_at_before}&cost_value=${filter.cSort}&price_value=${filter.pSort}`,
          signal: filter.controller.signal,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get all courses list
    getAllCoursesList ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/course/get_course_pay/?report="report"',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get a course
    getACourse ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/get_detail_course/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get list all lesson
    getLessonAll ({ commit }, { q, tag, degree, university, speciality, language, city, format, page }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/lesson/all/?q=${q}&tag=${tag}&degree=${degree}&university=${university}&speciality=${speciality}&lang=${language}&city=${city}&format_fil=${format}&page=${page}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get list lesson by course id with pagination
    getLessonListByCourseId ({ commit }, { id, page }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/lesson/${id}/list_lesson/?page=${page}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get list lesson by course id without pagination
    getLessonListByCourseIdWOutP ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/lesson/get_lesson_in_course_p/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete a lesson
    deleteALesson ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/lesson/destroy/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create course
    createCourse ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/course/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete a course
    deleteACourse ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/destroy/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to add posters to course
    addCoursePoster ({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/upload_posters/${id}/`,
          data: data,
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get tag (category) list
    getCategoryList ({ commit }, domain) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/tag/list/?domain=${domain}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get degree list
    getDegreeList ({ commit }, domain) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/degree/list/?domain=${domain}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get property list
    getPropertyList ({ commit }, domain) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/univer-tag/list/?domain=${domain}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to add property type of univer
    addProperty ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/univer-tag/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update property
    updateProperty ({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/univer-tag/update/${id}/`,
          data: { name: data },
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete faculty
    deleteProperty ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/univer-tag/delete/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create tag (category)
    createCategory ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/tag/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create degree
    createDegree ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/degree/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get teacher list
    getTeacherList ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/accounts/get_all_teacher/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get teacher list by course
    getTeacherListByCourse ({ commit }, courseId) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/list_teacher_group_create/?course=${courseId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get users list
    getUsersList ({ commit }, { role, q, count, day, page, joined, dateJoinedBefore, dateJoinedAfter }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/list/?role=${role}&q=${q}&av_course_count=${count}&day=${day}&page=${page}&order_by=${joined}&date_joined_after=${dateJoinedBefore}&date_joined_before=${dateJoinedAfter}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get profile
    getProfile ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/accounts/get_user_profile/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to send email to reset a password
    toResetPassword ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + '/api/v1/user/accounts/send_link_reset/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to get users by course
    getUserListByCourse ({ commit }, data) {
      const date = JSON.parse(localStorage.getItem('uData'))
      const schoolId = date.sd
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/course/group/list_users/${schoolId}/?role=${data.role}&course_id=${data.id}&page=${data.page}`,
          method: 'GET'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to get group users by course
    getGroupUserListByCourse ({ commit }, data) {
      const date = JSON.parse(localStorage.getItem('uData'))
      const schoolId = date.sd
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/course/group/list_users/${schoolId}/?role=${data.role}&course_id=${data.id}&group=${data.group}`,
          method: 'GET'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to get group list
    getGroupListByCourse ({ commit }, id) {
      const date = JSON.parse(localStorage.getItem('uData'))
      const schoolId = date.sd
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/course/group/list/${schoolId}/?course_id=${id}`,
          method: 'GET'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to get groups schedule list
    getGroupScheduleList ({ commit }, groupId) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/schedule/schedule/list_manager_group/${groupId}/`,
          method: 'GET'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to add teacher to course
    addTeacherCourse ({ commit }, { courseId, teacherId }) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/course/course/add_teacher/${courseId}/`,
          data: {
            teacher_id: teacherId
          },
          method: 'POST'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to add teacher to course
    addStudentCourse ({ commit }, { courseId, studId }) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/course/course/add_std/${courseId}/`,
          data: {
            student_id: studId
          },
          method: 'POST'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to delete teacher to course
    deleteTeacherCourse ({ commit }, { courseId, userId }) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/course/course/delete_teacher/${courseId}/${userId}/`,
          method: 'DELETE'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to delete teacher to group
    deleteStudentGroup ({ commit }, { userId, groupId }) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/course/group/delete_std/${groupId}/${userId}/`,
          method: 'DELETE'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to delete teacher to group
    deleteStudentFromCourse ({ commit }, { userId, courseId }) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/course/course/delete_student_in_course/${courseId}/${userId}/`,
          method: 'DELETE'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to delete group
    deleteGroup ({ commit }, groupId) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/course/group/delete/${groupId}/`,
          method: 'DELETE'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to get all teachers
    getTeachersList ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/accounts/get_all_teacher/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get users list
    getStudentListAccessCourse ({ commit }, courseId) {
      const date = JSON.parse(localStorage.getItem('uData'))
      const schoolId = date.sd
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/list_user_pay_courses_in_school/${schoolId}/${courseId}/?role=student`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete user by id
    deleteUserById ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/delete/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create user for admins
    createUser ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/accounts/manager_create/',
          data: user,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get users data by id
    getUsersDataById ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/get_user_by_id/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to check if has the email already
    checkHasEmail ({ commit }, email) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/check/email/?email=${email}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get teacher's group list
    getTeachersGroupId ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/list_group_teacher/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get work schedule by teacher id
    getWorkSchedule ({ commit }, userId) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/work_day/list/${userId}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create work schedule
    createWorkSchedule ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/schedule/work_day/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update work schedule by teacher id
    updateWorkSchedule ({ commit }, schedule) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/work_day/update/${schedule.id}/`,
          data: schedule,
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete work schedule by teacher id
    deleteWorkSchedule ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/work_day/delete/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get user by id
    getProfileData ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/get_profile/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to add group id
    createGroupId ({ commit }, { course, teacher }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/add_group_id_teacher/${teacher}/`,
          data: {
            courses: course
          },
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete teacher's group list
    removeTeachersGroupId ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/delete_group_teacher/${data[0]}/${data[1]}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete teacher's group list
    removePaidCourseFromTeacher ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/pay/delete/${data[0]}/${data[1]}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get communication list
    getCommunication ({ commit }, { studentId, from, to, page }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/communication_list/${studentId}/?created_after=${from}&created_before=${to}&page=${page}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get comment
    getComment ({ commit }, { studentId, from, to, page }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/comment_list/${studentId}/?created_after=${from}&created_before=${to}&page=${page}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create comment
    createComment ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/accounts/comment_create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get file list
    getFileList ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/get_all_files/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get list of available courses, which was paid
    getAvailableCourse ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/pay/list_pay/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to delete course from availableCourse
    deleteUsersPayment ({ commit }, { paymentId, userId }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/pay/delete/${paymentId}/${userId}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get count of lesson
    getCountOfLesson ({ commit }, { userId, courseId }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/list_count_lesson/${userId}/?course_id=${courseId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get students schedule
    getStudentsSchedule ({ commit }, { userId, lessonType }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/schedule/list_manager_student/${userId}/?type_lesson=${lessonType}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get students schedule
    getScheduleDetail ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/schedule/detail/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete students schedule
    deleteStudentsSchedule ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/schedule/schedule/delete/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create schedule by teacher id
    addSchedule ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/schedule/schedule/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get filtered teachers list by course id
    getFilteredTeacherByCourse ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/schedule/teacher_in_course/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get courses list in payment model
    getAllCourses ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/course/get_course_pay/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to logout
    logout ({ commit }) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('currency')
        localStorage.removeItem('uData')
        localStorage.removeItem('nb')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common.Authorization
        resolve()
      })
    },
    // functions to update users
    updateUsersDataWithoutPassword ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/manager_update/${user.id}/`,
          data: {
            first_name: user.first_name,
            last_name: user.last_name,
            gender: user.gender,
            email: user.email,
            phone: user.phone,
            country: user.country,
            role: user.role,
            birth_date: user.birth_date,
            degrees: user.degrees,
            countries: user.countries,
            packages: user.packages,
            steps: user.steps,
            acadmeic_year: user.acadmeic_year,
            responsible: user.responsible,
            type: user.type,
            payment_tranches: user.payment_tranches,
            package_cost: user.package_cost,
            payment_balance: user.payment_balance,
            ielts_preparation: user.ielts_preparation,
            ielts_present: user.ielts_present,
            ielts_teacher: user.ielts_teacher,
            ielts_test_date: user.ielts_test_date,
            visa: user.visa,
            visa_date: user.visa_date,
            visa_support: user.visa_support,
            visa_by_proxy: user.visa_by_proxy,
            visa_scheduled: user.visa_scheduled,
            visa_applied: user.visa_applied,
            visa_reschedule: user.visa_reschedule,
            guardian: user.guardian,
            visa_city: user.visa_city,
            visa_ready: user.visa_ready,
            legal_translator: user.legal_translator,
            legal_translation_ready: user.legal_translation_ready,
            legal_sent: user.legal_sent,
            legal_done: user.legal_done,
            legal_tracking_data: user.legal_tracking_data,
            ddv_sent: user.ddv_sent,
            ddv_done: user.ddv_done,
            ddv_tracking_number: user.ddv_tracking_number,
            codice_confirm: user.codice_confirm,
            codice_sent: user.codice_sent,
            pre_enrollment: user.pre_enrollment,
            awaiting_pre_enrollment: user.awaiting_pre_enrollment,
            pre_enrollment_present: user.pre_enrollment_present,
            grant_application_submitted: user.grant_application_submitted,
            accesses: user.accesses,
            departure_date: user.departure_date,
            residence_permit_applied: user.residence_permit_applied,
            codice_fiscali_applied: user.codice_fiscali_applied,
            caf_application: user.caf_application,
            sim_opened: user.sim_opened,
            housing_options: user.housing_options,
            housing_found: user.housing_found,
            financial_questionnaire: user.financial_questionnaire,
            financial_list_sent: user.financial_list_sent,
            financial_documents_ready: user.financial_documents_ready,
            apostille_submitted: user.apostille_submitted,
            apostille_done: user.apostille_done
          },
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    updateUsersDataWithPassword ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/manager_update/${user.id}/`,
          data: {
            password: user.password,
            first_name: user.first_name,
            last_name: user.last_name,
            gender: user.gender,
            email: user.email,
            phone: user.phone,
            country: user.country,
            role: user.role,
            birth_date: user.birth_date,
            degrees: user.degrees,
            countries: user.countries,
            packages: user.packages,
            steps: user.steps,
            acadmeic_year: user.acadmeic_year,
            responsible: user.responsible,
            type: user.type,
            payment_tranches: user.payment_tranches,
            package_cost: user.package_cost,
            payment_balance: user.payment_balance,
            ielts_preparation: user.ielts_preparation,
            ielts_present: user.ielts_present,
            ielts_teacher: user.ielts_teacher,
            ielts_test_date: user.ielts_test_date,
            visa: user.visa,
            visa_date: user.visa_date,
            visa_support: user.visa_support,
            visa_by_proxy: user.visa_by_proxy,
            visa_scheduled: user.visa_scheduled,
            visa_applied: user.visa_applied,
            visa_reschedule: user.visa_reschedule,
            guardian: user.guardian,
            visa_city: user.visa_city,
            visa_ready: user.visa_ready,
            legal_translator: user.legal_translator,
            legal_translation_ready: user.legal_translation_ready,
            legal_sent: user.legal_sent,
            legal_done: user.legal_done,
            legal_tracking_data: user.legal_tracking_data,
            ddv_sent: user.ddv_sent,
            ddv_done: user.ddv_done,
            ddv_tracking_number: user.ddv_tracking_number,
            codice_confirm: user.codice_confirm,
            codice_sent: user.codice_sent,
            pre_enrollment: user.pre_enrollment,
            awaiting_pre_enrollment: user.awaiting_pre_enrollment,
            pre_enrollment_present: user.pre_enrollment_present,
            grant_application_submitted: user.grant_application_submitted,
            accesses: user.accesses,
            departure_date: user.departure_date,
            residence_permit_applied: user.residence_permit_applied,
            codice_fiscali_applied: user.codice_fiscali_applied,
            caf_application: user.caf_application,
            sim_opened: user.sim_opened,
            housing_options: user.housing_options,
            housing_found: user.housing_found,
            financial_questionnaire: user.financial_questionnaire,
            financial_list_sent: user.financial_list_sent,
            financial_documents_ready: user.financial_documents_ready,
            apostille_submitted: user.apostille_submitted,
            apostille_done: user.apostille_done
          },
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get a data about course to edit
    getCourseData ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/get_detail_course/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get a data about course with lesson count
    getCourseInfo ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/detail/${data.id}/?translate_lang=${data.translate_lang}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create a lesson
    createALesson ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/lesson/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to add video to lesson
    addLessonVideo ({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/lesson/upload_video/${id}/`,
          data: data,
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get a lesson data
    getALesson ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/lesson/get_detail_lesson/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update a lesson data
    updateALesson ({ commit }, lesson) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/lesson/update/${lesson.id}/`,
          data: lesson,
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get a lesson data/ exercise list for lesson page
    getLessonsExList ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/ex/lst/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get a task data type 13 by exercise list
    getTask13ByExerciseList ({ commit }, { id, page }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/get_task_with_ex_list_id/${id}/?page=${page}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create exercise list
    createAExerciseList ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/ex/create_ex_lst/',
          data: {
            title_ru: data.title_ru,
            title_en: data.title_en,
            lesson_id: data.lessonId,
            next: data.next,
            min_percent: data.min_percent,
            time_for_timer: data.time_for_timer
          },
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update exercise list data
    updateAExerciseList ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/ex/update_ex_lst/${data.id}/`,
          data: {
            title_ru: data.title_ru,
            title_en: data.title_en,
            lesson_id: data.lesson_id,
            next: data.next,
            min_percent: data.min_percent,
            time_for_timer: data.time_for_timer
          },
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get exercise by exercise list id
    getExercisesByExerciseList ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/course/ex/get_ex_in_ex_lst/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to create an exercise
    createAnExercise ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/ex/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create doc task
    uploadToCreateDocTask ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/ex/ex_file_upload/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create doc task lib
    uploadToCreateDocTaskLib ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/ex/ex_file_upload/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create media task
    uploadToCreateMediaTask ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/ex/ex_file_upload/',
          data: data.file,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data; boundary=20'
          }
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete an exercise
    deleteAnExercise ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/ex/delete/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete exercise list
    deleteAExerciseList ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/ex/delete_ex_lst/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update an exercise
    updateATask ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/ex/update/${data[1]}/`,
          data: data[0],
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update a media file task
    updateATaskMediaFile ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/ex/update_file/${data[1]}/`,
          data: data[0],
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update a media file task
    updateATaskMediaFileOrder ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/ex/update_ex_file/${data[1]}/`,
          data: data[0],
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update a course data
    updateACourse ({ commit }, course) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/update/${course.id}/`,
          data: course,
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get courses list in payment model
    updateAvatar ({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/upload_avatar/${id}/`,
          data: data,
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get courses list in payment model
    createGroup ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/group/create/',
          data: {
            name_group: data.name_group,
            students: data.students,
            teacher: data.teacher,
            course: data.course,
            type_group: data.type_group
          },
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get users course list
    myCourseList ({ commit }, { translateLang, q, page, tag, degree, startDate, endDate }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/my/?q=${q}&page=${page}&tag=${tag}&degree=${degree}&start=${startDate}&end=${endDate}&translate_lang=${translateLang}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to check if course is avalable (retern boolean)
    checkCoursePay ({ commit }, courseId) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/check/check_payment/?course_id=${courseId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create a homework
    createAHomeWork ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/homework/create/',
          data: {
            title: data.title,
            desc: data.desc,
            lesson_id: data.lesson_id_id,
            course_id: data.course_id_id,
            grade: data.grade,
            ex_list: data.ex_list_id,
            task: data.task
          },
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create a homework file
    createAHomeWorkFile ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/homework/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to accept the homework file
    acceptHomeWork ({ commit }, { name, id }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/doc/${id}/`,
          data: {
            doc_name: name
          },
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to remove all homeworks in exercise list
    removeAnswers ({ commit }, exListId) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/homework/delete/${exListId}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update file doc task
    saveAnswers ({ commit }, { taskId, exListId }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/homework/get_ans_homework/${taskId}/${exListId}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update file doc task
    deleteAnswersFile ({ commit }, fileUrl) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/homework/delete-file/',
          data: {
            file_url: fileUrl
          },
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to add course in available course list
    addPaymentForUsersCourse ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/pay/create_course_user/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get all results for teachers
    getUsersResultsTeacher ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/homework/get_new_result_ex/${data[1]}/?student_id=${data[0]}&q=${data[2]}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get all results for admin
    getUsersResultsAdmin ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/homework/get_new_result_ex/${data[0]}/?q=${data[1]}&page=${data[2]}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get users available groups id
    getGroupId ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: apiUrl + `/api/v1/user/group/get_user_group/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // function to get groups by course id
    getGroupsByCourseId ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/get_group/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update paid courses data
    updateCoursesDataPaid ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/pay/update_payment/${data.id}/`,
          data: data,
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get recomended group id
    getRecomendedGroupId ({ commit }, { data, courseId, trialLesson }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/schedule/filter_teachers/?data=${data}&course=${courseId}&type_lesson=${trialLesson[0]}&start_date=${trialLesson[1]}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get if this teacher is free
    checkIsTeacherFree ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/schedule/filter_teachers/?day=${data.day}&time_after=${data.time_after}&time_before=${data.time_before}&course=${data.courseId}&teacher=${data.teacherId}&type_lesson=${data.type_lesson}&start_date=${data.start_date}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to send email to student
    sendEmail ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/lesson/send_report_student/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update students schedule
    updateStudentsSchedule ({ commit }, { userId, data }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/schedule/add/${userId}/`,
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to filter teacher to schedule
    filterTeacher ({ commit }, search) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/students_list/${search}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to filter students to schedule
    filterStudent ({ commit }, { search, teacherID }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/students_list/?group=${teacherID}&student=${search}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get work time
    getWorkTime ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/schedule/work_day/get_work_time/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get event to schedule page
    getEvents ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/schedule/list/?date_after=${data[0]}&date_before=${data[1]}&date=${data[5]}&teacher=${data[2]}&student=${data[3]}&typelesson=${data[4]}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get school detail
    getSchoolData ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/school/detail/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get profile
    getUsersData ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/accounts/get_user_profile/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    updateProfileDataWOutGroupidWP ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/update_profile/${user.id}/`,
          data: {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone: user.phone,
            password: user.password,
            country: user.country,
            role: user.role,
            birth_date: user.birth_date
          },
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    updateProfileDataWOutGroupidWOutP ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/update_profile/${user.id}/`,
          data: {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone: user.phone,
            country: user.country,
            role: user.role
          },
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get logo
    getLogo ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/school/get_logo/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get currency
    getCurrency ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/school/currency/list/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create school notif
    createSchoolNotif ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/school/send_mail/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete school notif
    deleteSchoolNotif ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/school/send_mail/delete/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get school notif
    getSchoolNotif ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/school/send_mail/list/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get data about users tariff
    getAboutUsersTariff ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/pay/get_my_tariff/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get a payment history of school
    getAPaymentHistory ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/pay/list_history_pay/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to calculate tariff
    getCalcTariff ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/pay/calc_tariff/?std=${data[0]}&month=${data[1]}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get avataor for profile
    getAvatarProfile ({ commit }) {
      const data = JSON.parse(localStorage.getItem('uData'))
      const id = data.id
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/get_avatar_profile/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get avataor for profile
    updateAvatarProfile ({ commit }, avatar) {
      const data = JSON.parse(localStorage.getItem('uData'))
      const id = data.id
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/update_avatar_profile/${id}/`,
          data: avatar,
          method: 'PUT',
          headers: {
            'Content-Type': 'multipart/form-data; boundary=20'
          }
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update school detail
    updateSchoolData ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/school/update/',
          data: data,
          method: 'PUT',
          headers: {
            'Content-Type': 'multipart/form-data; boundary=20'
          }
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to reset status
    resetStatusSchedule ({ commit }, scheduleId) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/schedule/cancel_status/${scheduleId}/`,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update status of schedule
    updateStatusSchedule ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/schedule/post_status/${data.scheduleId}/`,
          data: {
            status_lesson: data.status_lesson
          },
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report for main report page (realization)
    getChartsAmount ({ commit }, { date, courseId }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/report/mv/?start_date=${date.from}&end_date=${date.till}&course_id=${courseId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report for main report page (amount teach & stud)
    getChartsAmountTeachStud ({ commit }, { date, courseId }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/report/mv2/?start_date=${date.from}&end_date=${date.till}&course_id=${courseId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report for main report page (amount)
    getChartsRealization ({ commit }, { courseId, date }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/report/mv1/?start_date=${date.from}&end_date=${date.till}&course_id=${courseId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report by course for table
    getReportByCourseTable ({ commit }, { courseId, page, from, till }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/report/progress_course/?course_id=${courseId}&page=${page}&pub_date_after=${from}&pub_date_before=${till}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report by course for table list
    getReportByCourseTableList ({ commit }, { courseId, search, from, till, page }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/report/student_progress/${courseId}/?q=${search}&start_date=${from}&end_date=${till}&page=${page}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report by lesson chart
    getReportByLessonChart ({ commit }, { from, till, teacherId }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/report/teacher/?start_date=${from}&end_date=${till}&teacher_id=${teacherId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report for lessons table list
    getTableListData ({ commit }, { from, till, search, page }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/report/report_teacher_relation/?date_after=${from}&date_before=${till}&q=${search}&page=${page}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report on lesson page for cards today and month
    getLessonReportPageCardMT ({ commit }, { teacherId }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/report/teacher_report/?teacher_id=${teacherId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report by students count of lesson
    getStudentsCountLesson ({ commit }, { from, till, studId }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/report/report_student_count_lesson/?start_date=${from}&end_date=${till}&student_id=${studId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report by student chart
    getReportByStudentChart ({ commit }, { from, till, courseId, studId }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/report/report_student/?start_date=${from}&end_date=${till}&course_id=${courseId}&student_id=${studId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report of homework by program
    getReportHWByProgram ({ commit }, { search, from, till, courseId, page }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/homework/for_program/?q=${search}&start=${from}&end=${till}&course=${courseId}&page=${page}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report by student table
    getReportByStudentTable ({ commit }, { from, till, studId, courseId, page }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/report/student_homework/?homework_student__student_id=${studId}&pub_date_after=${from}&pub_date_before=${till}&id=${courseId}&page=${page}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report on teacher's page for cards
    getTeacherReportPageCardMT ({ commit }, studId) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/report/page_report_teacher/?student_id=${studId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get report on teacher's page
    getTeacherReportPage ({ commit }, { from, till, studId }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/schedule/report/teacher_report_page/?start_date=${from}&end_date=${till}&student_id=${studId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get group data by id
    getGroupData ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/group/detail_group/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update group data by id
    updateGroupData ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/group/update_group/${data.id}/`,
          data: data,
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get teachers list by group id
    getTeacherGroupId ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/group/list_teacher_group/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update tag/category
    updateCategory ({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/tag/update/${id}/`,
          data: { tag_name: data },
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete tag/category
    deleteCategory ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/tag/delete/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete tag/category
    deleteDegree ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/degree/delete/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update degree
    updateDegree ({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/degree/update/${id}/`,
          data: { degree_name: data },
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get max price of courses
    getMaxPrice ({ commit }, schoolId) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/get_max_price/?domain=${schoolId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get current currency
    getCurrentCurrency ({ commit }, schoolId) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/school/currency/code/?domain=${schoolId}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to copy course
    copyCourse ({ commit }, { courseId, userId }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/group/copy_course/${courseId}/${userId}/`,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to sign up students
    registerStudents ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/accounts/create/',
          data: user,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            commit('auth_error', err.response.message)
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    // function to send a group id to get homeworks
    sendingGroupId ({ commit }, groupId) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/homework/get_std_gr/${groupId}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get courses format
    getFormatList ({ commit }, host) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/format/list/?domain=${host}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to add format
    addFormat ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/format/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update format
    updateFormat ({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/format/update/${id}/`,
          data: { format_name: data },
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete format
    deleteFormat ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/format/delete/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get courses univer
    getUniverList ({ commit }, { host, tag }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/univer/list/?domain=${host}&tag_id=${tag}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to add univer
    addUniver ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/univer/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update univer
    updateUniver ({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/univer/update/${id}/`,
          data: { name: data },
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete univer
    deleteUniver ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/univer/delete/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get courses faculty
    getFacultyList ({ commit }, host) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/spec/list/?domain=${host}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to add faculty
    addFaculty ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/spec/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update faculty
    updateFaculty ({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/spec/update/${id}/`,
          data: { name: data },
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete faculty
    deleteFaculty ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/spec/delete/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete city
    deleteCity ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/city/delete/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to upd city
    updateCity ({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/city/update/${id}/`,
          data: { city: data },
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to add city
    addCity ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/city/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get courses city
    getCityList ({ commit }, { host, tag }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/city/list/?domain=${host}&tag_id=${tag}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get document grouped by course
    getDocumentGrouped ({ commit }, { studentId, courseId }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/get_all_files/${studentId}/${courseId}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get school description
    getSchoolDesc ({ commit }, domain) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/school/guest-info-school/?domain=${domain}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get whether homework checked
    getCheckedHomework ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/check/check_next_page/${data[0]}/${data[1]}/${data[2]}/${data[3]}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to add education
    addEducation ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/education/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get education
    getEducation ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/education/list/?user_id=${id}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get education by education id
    getEducationByEduId ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/education/detail/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update educate data by id
    updateEducation ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/education/update/${data.id}/`,
          data: data,
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete educate data by id
    deleteEducation ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/education/delete/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to add certificate
    addCertificate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/certificate/create/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get certificate
    getCertificate ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/certificate/list/?user_id=${id}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update certificate data by id
    updateCertificate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/certificate/update/${data.id}/`,
          data: data,
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete certificate data by id
    deleteCertificate ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/certificate/delete/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to change first visit
    changeFirstVisit ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/accounts/first_visit/',
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete users ava
    deleteAvatar ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/delete_avatar_profile/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to create payment history
    createAPaymentHistory ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/pay/create_history_pay/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to add course to paid course list
    payACourse ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/pay/pay_course/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to send sms verify
    smsSend ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/accounts/generate_otp/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to check sms verify
    checkOtp ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/check_otp/?phone=${data.phone}&email=${data.email}&otp=${data.otp}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get faq detail
    faqDetail ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/detail_faq/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get faqs list chapter
    faqListChapter ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/list_chapter/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get list faq id
    faqIdList ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/list_faq/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get user's course tracker by course id
    getCourseTracker ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/ex/get_course_tracker/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get course tracker by course id and user id
    getCourseTrackerByUser ({ commit }, ids) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/ex/get_course_tracker/${ids[0]}/${ids[1]}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get user's ex_list tracker by ex_list id
    getExListTracker ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/ex/get_ex_list_tracker/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to save dates(rounds) of course
    saveRounds ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/course/set_filter/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get dates(rounds) of course
    getRounds ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/get_filter/?course_id=${id}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update dates(rounds) of course
    updateRounds ({ commit }, { data, id }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/update_filter/${data.id}/`,
          data: {
            start: data.start,
            end: data.end,
            id: id
          },
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete round data
    deleteRound ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/delete_filter/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function of courses massive updating
    massiveUpdate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/group/update_courses/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function of lessons massive updating
    massiveLessonUpdate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/lesson/update_lessons/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function get unverified users list
    getUnverfyUserList ({ commit }, { search, page }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/homework/sort/?q=${search}&page=${page}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function upload file to library
    uploadToLib ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/my-files/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to copy lesson
    copyLesson ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/lesson/copy/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to add language knowledge
    addLanguage ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/accounts/mastered-language/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get language knowledge
    getLanguage ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/mastered-language/?user_id=${id}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete language knowledge
    deleteLanguage ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/mastered-language/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get personal users code
    getCode ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/accounts/code/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get education analytic data
    getEducationAnalyticData ({ commit }, { page, degree, start, end }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/education/?page=${page}&degree=${degree}&start=${start}&end=${end}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get language analytic data
    getLanguageAnalyticData ({ commit }, { page, language, certif, level }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/knowledge_language/?page=${page}&language=${language}&certificate=${certif}&level=${level}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to save certificate reqierments of program
    addCertifReq ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/course/requirement-certificates/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to update certificate reqierments of program
    updateCertifReq ({ commit }, { id, data }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/requirement-certificates/${id}/`,
          data: data,
          method: 'PATCH'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete certificate reqierments of program
    delCertifReq ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/course/requirement-certificates/',
          data: data,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get languages and certificates list
    getLanguagesCertifList ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/course/course/requirements/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get program list with sorting
    getSortedProgram ({ commit }, filter) {
      return new Promise((resolve, reject) => {
        const date = JSON.parse(localStorage.getItem('uData'))
        const schoolId = date.sd
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/course/course/list/${schoolId}/?q=${filter.q}&univer_tag=${filter.univer_tag}&university=${filter.univer}&speciality=${filter.faculty}&cost_min=${filter.cost_min}&city=${filter.city}&cost_max=${filter.cost_max}&price_min=${filter.price_min}&price_max=${filter.price_max}&tag=${filter.tag}&degree=${filter.degree}&lang=${filter.lang}&translate_lang=${filter.translate_lang}&page=${filter.page}&domain=${filter.domain}&start=${filter.start_date}&end=${filter.end_date}&is_open=${filter.is_open}&format_fil=${filter.format}&cost_value=${filter.cSort}&price_value=${filter.pSort}`,
          signal: filter.controller.signal,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get final doc list
    getFinalDocList ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/upload_doc/${id}/`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to upload final doc
    uploadFinalDoc ({ commit }, { id, data }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/upload_doc/${id}/`,
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to delete final doc
    deleteFinalDoc ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/delete_doc/${id}/`,
          method: 'DELETE'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get mailing users
    getMailingUsers ({ commit }, { degree, country, packages, page }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/mailing_users/?degrees=${degree}&countries=${country}&packages=${packages}&page=${page}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get mailing users
    getPackageList ({ commit }, name) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/package_list/?name=${name}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get countries
    getCountries ({ commit }, name) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/country_list/?name=${name}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get degrees
    getDegrees ({ commit }, name) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + `/api/v1/user/accounts/degree_list/?name=${name}`,
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to send mailing
    sendMailing ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/accounts/send_tg_mailing/',
          data: data,
          method: 'POST'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get legal translation
    getLegalTranslation ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/selectors/legal_translation_ready/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get city for users
    getCityForUsers ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/selectors/city/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get legal translator
    getLegalTranslator ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/selectors/legal_translator/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get steps
    getSteps ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/selectors/steps/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get types
    getTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/selectors/types/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get visa support
    getVisaSupport ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/selectors/visa_support/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    },
    // function to get academic year
    getAcademicYear ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('preloaderStatus')
        axios({
          url: apiUrl + '/api/v1/user/selectors/academic_years/',
          method: 'GET'
        })
          .then(resp => {
            commit('preloaderStatus')
            resolve(resp)
          })
          .catch(err => {
            commit('preloaderStatus')
            reject(err)
          })
      })
    }
  },
  modules: {
  }
})
