import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'course',
    component: () => import('../views/courses/CoursePage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Программа',
      layout: 'Main',
      roles: ['admin_school']
    }
  },
  {
    path: '/my-course',
    name: 'my-course',
    component: () => import('../views/courses/myCourse/MyCourse.vue'),
    meta: {
      requiresAuth: true,
      title: 'Мои программы',
      layout: 'Main',
      roles: ['admin', 'admin_school', 'teacher', 'student']
    }
  },
  {
    path: '/lessons',
    name: 'lessons',
    component: () => import('../views/lesson/allLessons/AllLessons.vue'),
    meta: {
      requiresAuth: true,
      title: 'Разделы',
      layout: 'Main',
      roles: ['admin', 'admin_school', 'teacher', 'student']
    }
  },
  {
    path: '/course-add',
    name: 'course-add',
    component: () => import('@/components/courses/manage/AddProgram.vue'),
    meta: {
      requiresAuth: true,
      title: 'Создать программу',
      layout: 'Main',
      roles: ['admin_school']
    }
  },
  {
    path: '/course-edit/:courseId',
    name: 'course-edit',
    component: () => import('@/views/courses/CourseEdit.vue'),
    meta: {
      requiresAuth: true,
      title: 'Редактировать программу',
      layout: 'Main',
      roles: ['admin_school']
    }
  },
  {
    path: '/course/:courseId/lesson-add',
    name: 'lesson-add',
    component: () => import('../views/lesson/LessonAdd.vue'),
    meta: {
      requiresAuth: true,
      title: 'Создать раздел',
      layout: 'Main',
      roles: ['admin_school']
    }
  },
  {
    path: '/course/:courseId/lesson-edit/:lessonId',
    name: 'lesson-edit',
    component: () => import('../views/lesson/LessonEdit.vue'),
    meta: {
      requiresAuth: true,
      title: 'Редактировать раздел',
      layout: 'Main',
      roles: ['admin_school']
    }
  },
  {
    path: '/course/:courseId/lesson/:lessonId/constructor',
    name: 'constructor',
    component: () => import('../views/lesson/ConstructorPage.vue'),
    meta: {
      requiresAuth: true,
      title: 'Конструктор',
      layout: 'Main',
      roles: ['admin', 'admin_school', 'teacher', 'student']
    }
  },
  {
    path: '/course/view/:courseId',
    name: 'course-catalog-view',
    component: () => import('../views/catalog/CatalogInfo.vue'),
    meta: {
      requiresAuth: true,
      // title: 'Курс подробнее',
      layout: 'Main',
      roles: ['admin', 'admin_school', 'teacher', 'student']
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/LoginPage.vue'),
    meta: {
      title: 'Авторизация',
      layout: 'Empty'
    }
  },
  {
    path: '/set-password',
    name: 'set-password',
    component: () => import('../views/login/ForgetPassword.vue'),
    meta: {
      title: 'Восстановить пароль',
      layout: 'Empty'
    }
  },
  {
    path: '/user-registrate',
    name: 'user-registrate',
    component: () => import('../views/login/RegistratePage.vue'),
    meta: {
      title: 'Регистрация пользователя',
      layout: 'Empty'
    }
  },
  {
    path: '/guest-catalog',
    name: 'guest-catalog',
    component: () => import('../components/guest/GuestCatalogList.vue'),
    meta: {
      title: 'Каталог для гостей',
      layout: 'Guest'
    }
  },
  {
    path: '/guest-catalog/info/:courseId',
    name: 'guest-catalog-info',
    component: () => import('../views/guestPages/GuestCatalogInfo.vue'),
    meta: {
      title: 'Информация о программе',
      layout: 'Guest'
    }
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: () => import('../views/catalog/CatalogPage.vue'),
    meta: {
      title: 'Каталог',
      requiresAuth: true,
      layout: 'Main',
      roles: ['admin', 'admin_school', 'teacher', 'student']
    }
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import('../views/analytic/AnalyticPage.vue'),
    meta: {
      title: 'Аналитика',
      requiresAuth: true,
      layout: 'Main',
      roles: ['admin', 'admin_school', 'teacher', 'student']
    }
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: () => import('../views/schedule/SchedulePage.vue'),
    meta: {
      title: 'Расписание',
      requiresAuth: true,
      layout: 'Main',
      roles: ['admin', 'admin_school', 'teacher', 'student']
    }
  },
  {
    path: '/mail-success',
    name: 'mail-success',
    component: () => import('../views/login/MailSent.vue'),
    meta: {
      title: 'Письмо отправлено',
      layout: 'Empty'
    }
  },
  {
    path: '/course/info/:courseId',
    name: 'course-info',
    component: () => import('../views/courses/CourseInfo.vue'),
    meta: {
      requiresAuth: true,
      layout: 'Main',
      roles: ['admin', 'admin_school', 'teacher']
    }
  },
  {
    path: '/my-course/info/:courseId',
    name: 'my-course-info',
    component: () => import('../views/courses/myCourse/MyCourseInfo'),
    meta: {
      requiresAuth: true,
      layout: 'Main',
      roles: ['admin', 'admin_school', 'teacher', 'student']
    }
  },
  {
    path: '/group-add/:courseId',
    name: 'group-add',
    component: () => import('../views/courses/GroupAdd.vue'),
    meta: {
      requiresAuth: true,
      layout: 'Main',
      roles: ['admin_school']
    }
  },
  {
    path: '/group-edit/:courseId/:groupId',
    name: 'group-edit',
    component: () => import('../views/courses/GroupEdit.vue'),
    meta: {
      requiresAuth: true,
      layout: 'Main',
      roles: ['admin_school']
    }
  },
  {
    path: '/users-list',
    name: 'users-list',
    component: () => import('../views/users/userlist/UsersList.vue'),
    meta: {
      requiresAuth: true,
      layout: 'Main',
      roles: ['admin_school', 'admin']
    }
  },
  {
    path: '/users-add',
    name: 'users-add',
    component: () => import('../views/users/UserAdd.vue'),
    meta: {
      requiresAuth: true,
      layout: 'Main',
      roles: ['admin_school', 'admin']
    }
  },
  {
    path: '/course/view/:courseId/lesson/:lessonId',
    name: 'lesson-view',
    component: () => import('../views/lesson/lessonPage/LessonPage.vue'),
    meta: {
      requiresAuth: true,
      layout: 'Main',
      roles: ['admin', 'admin_school', 'teacher', 'student']
    }
  },
  {
    path: '/user/teacher/view/:userId',
    name: 'teachers-page',
    component: () => import('../views/users/teachersPage/TeachersPage'),
    meta: {
      requiresAuth: true,
      title: 'Преподаватель',
      layout: 'Main',
      roles: ['admin_school', 'admin']
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/settings/SettingMain.vue'),
    meta: {
      title: 'Настройки',
      requiresAuth: true,
      layout: 'Main',
      roles: ['admin', 'admin_school', 'teacher', 'student']
    }
  },
  {
    path: '/user/view/:userId',
    name: 'users-page',
    component: () => import('../views/users/usersPage/UsersPage'),
    meta: {
      requiresAuth: true,
      title: 'Пользователи',
      layout: 'Main',
      roles: ['admin_school', 'admin']
    }
  },
  {
    path: '/success/tariff/:orderId/:tariffPrice/:currency/:student/:month',
    name: 'success-page',
    component: () => import('../views/settings/SuccessPage'),
    meta: {
      requiresAuth: true,
      title: 'Успешно',
      layout: 'Empty'
    }
  },
  {
    path: '/success/course/:orderId/:courseId/:courseCost/:currency',
    name: 'success',
    component: () => import('../views/catalog/SuccessPageCatalog'),
    meta: {
      requiresAuth: true,
      title: 'Успешно',
      layout: 'Empty'
    }
  },
  {
    path: '/login/verify',
    name: 'verify',
    component: () => import('../views/login/VerifPage.vue'),
    meta: {
      title: 'Аутентификация',
      layout: 'Empty'
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/login/ErrorPage.vue'),
    meta: {
      title: 'Страница не найдена',
      layout: 'Empty'
    }
  },
  {
    path: '/phone',
    name: 'phone',
    component: () => import('../views/login/PhoneInputPage.vue'),
    meta: {
      title: 'Номер телефона',
      layout: 'Empty'
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      const role = JSON.parse(localStorage.getItem('uData')).rle
      if (to.meta?.roles?.includes(role)) {
        next()
      } else {
        localStorage.setItem('nb', 6)
        next('/catalog')
      }
    } else {
      next('/guest-catalog')
    }
  } else {
    next()
  }
})

export default router
